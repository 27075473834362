<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>

    <v-card class="page-card-wrapper">
      <v-row no-gutters justify="center">
        <v-col cols="6">
          <FormLayout1 :noLabel="true">
            <v-card-title class="px-0 mb-2 text-capitalize font-weight-regular">
              <v-icon class="mr-2">mdi-account-tie</v-icon>
              {{ $t('text.personalInformation') }}
            </v-card-title>
          </FormLayout1>
          <v-form ref="userDetailsForm">
            <template v-for="(item, index) in filteredFormFields">
              <FormLayout1 :key="index" :label="$t(`text.${item.label}`)">
                <AppFormField
                  ref="formField"
                  v-model="item.model"
                  :outlined="true"
                  :dense="true"
                  :type="item.type"
                  :allowShowPassword="true"
                  :rules="
                    item.key != 'confirmPassword'
                      ? item.rules
                      : validation.confirmPasswordRules(
                          formFields.find((x) => x.key == 'password').model,
                          item.model
                        )
                  "
                  :readonly="
                    ['username', 'name'].includes(item.key) ? isReadonly : false
                  "
                  class="mt-1"
                ></AppFormField>
              </FormLayout1>
            </template>
            <FormLayout1 v-if="showChangePassword" :noLabel="true">
              <v-btn
                block
                depressed
                color="primary"
                class="text-caption"
                @click="changePassword = true"
                >{{ $t('text.changePassword') }}?</v-btn
              >
            </FormLayout1>
          </v-form>

          <FormLayout1 v-if="isEditableSuperAdmin" :noLabel="true" class="mt-6">
            <v-btn
              depressed
              outlined
              class="text-caption mr-3"
              @click="resetForm"
              >{{ $t('text.reset') }}</v-btn
            >
            <v-btn
              depressed
              color="primary"
              class="text-caption"
              @click="saveChanges"
              >{{ $t('text.saveChanges') }}</v-btn
            >
          </FormLayout1>
        </v-col>
      </v-row>

      <v-divider class="my-8"></v-divider>

      <v-row no-gutters justify="center">
        <v-col cols="6">
          <template v-for="(i, index) in dataFields">
            <FormLayout1 :key="index" :label="$t(`text.${i}`)" class="mb-4">
              <div class="text-body-1">{{ userObj[i] }}</div>
            </FormLayout1>
          </template>
        </v-col>
      </v-row>

      <v-divider v-if="showDelete" class="my-8"></v-divider>

      <div v-if="showDelete" class="d-flex justify-center">
        <v-btn
          min-width="300"
          depressed
          color="error"
          class="text-caption"
          @click="requestDelete(id)"
          >{{ $t('text.deleteUser') }}</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'
import FormLayout1 from '@/components/form/FormLayout-1'
import { ROUTE_NAME, FORM_FIELD_TYPE, PERMISSIONS } from '@/constants'
import { formValidationHelper, i18nHelper } from '@/utils'
import {
  USER_GET_USER,
  USER_UPDATE_USER,
  USER_DELETE_USER,
  USER_INITIAL_GET_USER_STATE,
  USER_INITIAL_UPDATE_USER_STATE,
  USER_INITIAL_DELETE_USER_STATE
} from '@/store/user.module'

export default {
  name: 'UserDetails',
  components: {
    FormLayout1
  },
  mixins: [commonMixin, crudMixin],
  data: () => ({
    id: null,
    validation: formValidationHelper,
    permissions: PERMISSIONS,
    pageTitle: 'userDetails',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'user',
        disabled: false,
        to: { name: ROUTE_NAME.USER_LIST }
      },
      {
        text: 'details',
        disabled: true
      }
    ],
    userObj: {},
    formFields: [
      {
        model: '',
        label: 'username',
        key: 'username',
        rules: formValidationHelper.usernameRules(),
        field: 1
      },
      {
        model: '',
        label: 'name',
        key: 'name',
        rules: formValidationHelper.requiredRules(),
        field: 1
      },
      {
        model: '',
        label: 'password',
        key: 'password',
        type: FORM_FIELD_TYPE.PASSWORD,
        rules: formValidationHelper.passwordRules(),
        field: 2
      },
      {
        model: '',
        label: 'confirmPassword',
        key: 'confirmPassword',
        type: FORM_FIELD_TYPE.PASSWORD,
        field: 2
      }
    ],
    dataFields: ['createdAt', 'updatedAt'],
    actions: {
      readRecord: {
        module: 'user',
        state: 'user',
        action: USER_GET_USER,
        title: i18nHelper.getMessage('text.getUserDetails'),
        successAction: () => {}
      },
      initialReadRecordState: USER_INITIAL_GET_USER_STATE,
      updateRecord: {
        module: 'user',
        state: 'updateUser',
        action: USER_UPDATE_USER,
        title: i18nHelper.getMessage('text.updateUserDetails'),
        successAction: () => {}
      },
      initialUpdateRecordState: USER_INITIAL_UPDATE_USER_STATE,
      deleteRecord: {
        module: 'user',
        state: 'deleteUser',
        action: USER_DELETE_USER,
        title: i18nHelper.getMessage('text.deleteUser'),
        successAction: () => {}
      },
      initialDeleteRecordState: USER_INITIAL_DELETE_USER_STATE
    },
    changePassword: false
  }),
  computed: {
    filteredFormFields() {
      return this.changePassword
        ? this.formFields
        : this.formFields.filter((x) => x.field == 1)
    },
    meData() {
      return this.$store.state.auth.me.data
    },
    showChangePassword() {
      let show = false

      if (!this.changePassword) {
        show = true
        if (!this.isEditableSuperAdmin) {
          show = false
        }
      }

      return show
    },
    isSuperAdmin() {
      return this.userObj.username == 'superadmin'
    },
    showDelete() {
      return !this.isSuperAdmin && this.meData.username != this.userObj.username
    },
    isEditableSuperAdmin() {
      let isEditable = true

      if (this.userObj.username == 'superadmin') {
        if (this.meData.username != 'superadmin') {
          isEditable = false
        }
      }

      return isEditable
    },
    isReadonly() {
      let isReadonly = false

      if (
        this.userObj.username == 'superadmin' ||
        this.meData.username == this.userObj.username
      ) {
        isReadonly = true
      }

      return isReadonly
    }
  },
  created() {
    this.initUserDetails()
  },
  methods: {
    saveChanges() {
      if (this.$refs.userDetailsForm.validate()) {
        this.requestUpdate(this.id, this.mapArrayToObjectData(this.formFields))
      }
    },
    resetForm() {
      this.$refs.userDetailsForm.reset()
      this.changePassword = false
      this.readRecord(this.id)
    },
    importUserData(response) {
      let data = response.data

      this.formFields.forEach((x) => {
        this.formFields.find((y) => y.key == x.key).model = data[x.key]
      })
      this.userObj = data
    },
    importSuccessAction() {
      this.actions.readRecord.successAction = (response) => {
        this.importUserData(response)
      }

      this.actions.updateRecord.successAction = () => {
        this.resetForm()
      }

      this.actions.deleteRecord.successAction = () => {
        this.backToParentPage(ROUTE_NAME.USER)
      }
    },
    initUserDetails() {
      let id = this.$route.params.id

      this.id = id
      this.readRecord(id)
      this.importSuccessAction()
    }
  }
}
</script>

<style></style>
